import React from "react";
import Layout from "components/layout";
import { withI18next } from "lib/withI18next";
import { autobind } from "react-decoration";
import { page } from "components/page";
import { navigate } from "gatsby";
import qs from "query-string";
import { Base64 } from 'js-base64';

@withI18next(["common"])
@page
class SendURLApiV3EDS extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      auth: props.readerStore.auth,
    };
  }

  componentDidMount() {
    this.sendURLApiEDS(this.props);
  }

  componentWillReceiveProps(props) {
    if (this.state.auth !== props.readerStore.auth) {
      this.setState({ auth: props.readerStore.auth });
      this.sendURLApiEDS(props);
    }
  }

  @autobind
  sendURLApiEDS(props) {
    let url = "";
    let splink = "";
    let title = "";
    let { location } = props;
    let { search = "" } = location;
    if (search !== "") {
      search = search.substring(1);
    }
    let params = qs.parseUrl(window.location.href).query;
    let { pr = "", mode = "" } = params;
    let start = search.indexOf("url=");
    if (start >= 0) {
			url = search.substring(start+4);
		}
    {
      let start2 = search.indexOf("&title=");
      if (start2 >= 0) {
        title = search.substring(start2+7);
        let end2 = title.indexOf("&");
        if(title.indexOf("&") > 0){
          title = title.substring(0, end2);
        }
      }
    }
    {
      let start2 = search.indexOf("&splink=");
			if(start2 >= 0){
				splink = search.substring(start2+8);
				let end2 = splink.indexOf("&");
				if(splink.indexOf("&") > 0){
					splink = splink.substring(0,end2);
          splink = Base64.decode(decodeURI(splink).replace(new RegExp("\n","gm"),""));
					splink = splink.substring(4 + (splink.indexOf("pr=hy&") === 0 ? 6 : 0 ) );
				}
			}
    }
    if(splink !== ""){
			url = splink;
		}
    if (url !== "") {
      if (url.indexOf("library.artstor.org") > 0 && url.charAt(url.length()-1) === "?") {
        url = url.substring(0, url.length()-1);
      }
      let redirectParams = {
        mode,
        url,
        title,
      }
      if (pr === "hy") {
        redirectParams.pr = "hy";
      }
      navigate(["/sendURLApiV3", qs.stringify(redirectParams)].join("?"));
    }
  }

  render() {
    let { t, isprivate, i18n } = this.props;
    return (
      <Layout t={t} i18n={i18n} isprivate={isprivate} hiddenBg={true}>
        <div
          ref={(c) => {
            this.container = c;
          }}
        />
      </Layout>
    );
  }
}

export default SendURLApiV3EDS;
